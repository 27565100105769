import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber',
  standalone: true,
})
export class PhoneNumberPipe implements PipeTransform {
  transform(phone: string): string {
    if (!phone) return '-';

    const fullPhone = phone.slice(0, 1) === '+' ? phone : '+' + phone;

    if (fullPhone.length < 12) return fullPhone;

    return `${fullPhone.slice(0, 2)} ${fullPhone.slice(2, 5)} ${fullPhone.slice(
      5,
      8,
    )} ${fullPhone.slice(8, 12)}`;
  }
}
